import { create } from '@hanwhalife/shared-libs/zustand';

/**
 * Web Keypad
 */
interface IWebKeypad {
  isKeypadLoaded: boolean;
  setKeypadLoaded: (flag: boolean) => void;
}

export const useWebKeypadStore = create<IWebKeypad>((set) => ({
  isKeypadLoaded: false,
  setKeypadLoaded: (flag) => {
    set((state) => ({ isKeypadLoaded: flag }));
  }
}));
