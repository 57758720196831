import { useHttpClient } from '@hanwhalife/http-client';
import {
  useQuery,
  UseQueryOptions,
  QueryObserverResult,
  UseQueryResult,
  UseMutationOptions,
  UseMutationResult,
  useMutation
} from '@hanwhalife/shared-libs/react-query';
import { AxiosError } from '@hanwhalife/shared-libs/axios';
import {
  loginAPIRequest,
  logoutAPIRequest,
  updateTokenThroughRefreshToken,
  updateFreshTokenThroughRefreshToken,
  updateNativeFreshTokenThroughRefreshToken,
  cookieClearRequest,
  getAuthTokenIntrospect,
  getAuthTokenIntrospectForNonCookie
} from './auth';
import { IFetchErrorModel } from '@hanwhalife/ts-types';
import {
  ILoginOutput,
  IDummyOutput,
  IAuthTokenIntrospectInput,
  IAuthTokenIntrospectResponse,
  IRefreshTokenOutput
} from './auth.types';
import { IIntegratedAuthenticationConfirm } from '../cert/cert.types';

/**
 * 토큰갱신 react-query
 * @param options
 * @returns
 */
export const useRefreshTokenQuery = (
  options?: UseQueryOptions<IDummyOutput, AxiosError, string, ['updateTokenThroughRefreshToken']>
): QueryObserverResult<IDummyOutput> => {
  const API = useHttpClient();

  const response = useQuery(['updateTokenThroughRefreshToken'], () => updateTokenThroughRefreshToken(API), {
    refetchInterval: 1000 * 60 * 5,
    refetchIntervalInBackground: true,
    refetchOnMount: true,
    retry: Infinity,
    ...options
  });

  return response as UseQueryResult<IDummyOutput>;
};

/**
 * 토큰갱신(강제) react-query
 */
export const useFreshTokenThroughRefreshToken = (
  options?: UseMutationOptions<IDummyOutput, IFetchErrorModel, undefined>
): UseMutationResult<IDummyOutput, IFetchErrorModel, undefined> => {
  const API = useHttpClient();

  const response = useMutation(() => updateFreshTokenThroughRefreshToken(API), {
    mutationKey: ['updateFreshTokenThroughRefreshToken'],
    ...options
  });

  return response;
};

export const useNativeFreshTokenThroughRefreshToken = (
  options?: UseMutationOptions<IRefreshTokenOutput, IFetchErrorModel, { refreshToken: string }>
): UseMutationResult<IRefreshTokenOutput, IFetchErrorModel, { refreshToken: string }> => {
  const API = useHttpClient();

  const response = useMutation<IRefreshTokenOutput, IFetchErrorModel, { refreshToken: string }>(
    (param: { refreshToken: string }) => updateNativeFreshTokenThroughRefreshToken(API, param),
    {
      mutationKey: ['updateNativeFreshTokenThroughRefreshToken'],
      ...options
    }
  );

  return response;
};

/**
 * 로그인 react-query
 * @param params
 * @returns
 */
export const useLoginAPIRequestQuery = (
  options?: UseMutationOptions<ILoginOutput, IFetchErrorModel, IIntegratedAuthenticationConfirm>
): UseMutationResult<ILoginOutput, IFetchErrorModel, IIntegratedAuthenticationConfirm> => {
  const API = useHttpClient();

  const response = useMutation(
    (param: IIntegratedAuthenticationConfirm) => {
      return loginAPIRequest(API, param);
    },
    { mutationKey: ['useLoginAPIRequest'], ...options }
  );

  return response;
};

/**
 * 로그아웃 react-query
 * @param params
 * @returns
 */
export const useLogoutAPIRequestQuery = (
  options?: UseMutationOptions<IDummyOutput, IFetchErrorModel, null>
): UseMutationResult<IDummyOutput, IFetchErrorModel, null> => {
  const API = useHttpClient();

  const response = useMutation(
    () => {
      return logoutAPIRequest(API);
    },
    { mutationKey: ['logoutAPIRequest'], ...options }
  );

  return response;
};

/**
 * 쿠키 초기화 쿼리
 * @param options
 * @returns
 */
type RequestCookieClearType = 'default' | 'withdrawal';
export const useCookieClearRequestQuery = (
  options?: UseQueryOptions<IDummyOutput, AxiosError, IDummyOutput, ['cookieClearRequest', RequestCookieClearType]> & {
    /** custom query key value */
    queryPurpose?: RequestCookieClearType;
  }
): UseQueryResult<IDummyOutput> => {
  const API = useHttpClient();
  const queryPurposeKey = options?.queryPurpose ? options.queryPurpose : 'default';

  const response = useQuery(['cookieClearRequest', queryPurposeKey], () => cookieClearRequest(API), options);

  return response;
};

/**
 * 토큰검증 react-query
 */
export const useAuthTokenIntrospectQuery = (
  options?: UseQueryOptions<IDummyOutput, IFetchErrorModel, IDummyOutput, ['getAuthTokenIntrospect']>
): UseQueryResult<IDummyOutput, IFetchErrorModel> => {
  const API = useHttpClient();

  const response = useQuery(['getAuthTokenIntrospect'], () => getAuthTokenIntrospect(API), {
    ...options
  });

  return response;
};

/**
 * 토큰검증 react-query(non cookie)
 */
export const useAuthTokenIntrospectForNoneCookieQuery = (
  param: IAuthTokenIntrospectInput,
  options?: UseQueryOptions<
    IAuthTokenIntrospectResponse,
    IFetchErrorModel,
    IAuthTokenIntrospectResponse,
    ['getAuthTokenIntrospectForNonCookie']
  >
): UseQueryResult<IAuthTokenIntrospectResponse, IFetchErrorModel> => {
  const API = useHttpClient();

  const response = useQuery(
    ['getAuthTokenIntrospectForNonCookie'],
    () => getAuthTokenIntrospectForNonCookie(API, param),
    {
      ...options
    }
  );

  return response;
};
